// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-gatsby-theme-fgs-blog-pages-blog-jsx": () => import("./../../../src/gatsby-theme-fgs-blog/pages/blog.jsx" /* webpackChunkName: "component---src-gatsby-theme-fgs-blog-pages-blog-jsx" */),
  "component---src-gatsby-theme-fgs-blog-templates-blog-post-jsx": () => import("./../../../src/gatsby-theme-fgs-blog/templates/blog-post.jsx" /* webpackChunkName: "component---src-gatsby-theme-fgs-blog-templates-blog-post-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-data-privacy-js": () => import("./../../../src/pages/data-privacy.js" /* webpackChunkName: "component---src-pages-data-privacy-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mailing-list-js": () => import("./../../../src/pages/mailing-list.js" /* webpackChunkName: "component---src-pages-mailing-list-js" */)
}

